import { init as initFullStory, FullStory } from '@fullstory/browser'
import { createContext, useState, useEffect } from 'react'

export const FullstoryContext = createContext({
  isFullstoryInitialized: false,
})

export const FullstoryProvider = ({ children }: { children?: React.ReactNode }) => {
  const [isFullstoryInitialized, setIsFullstoryInitialized] = useState(false)

  useEffect(() => {
    if (process.env.BUILD_MODE === 'staging') {
      initFullStory({
        orgId: process.env.FULLSTORY_ORG_ID,
        host: 'dev.app.getapron.com/fs',
        script: 'cdn.getapron.com/scripts/fs.js',
      })

      FullStory('observe', {
        type: 'start',
        callback: () => {
          setIsFullstoryInitialized(true)
        },
      })
    }
  }, [])

  return (
    <FullstoryContext.Provider value={{ isFullstoryInitialized }}>
      {children}
    </FullstoryContext.Provider>
  )
}
