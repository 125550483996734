import type { VariantProps } from '@stitches/react'
import { Avatar, IconButton, Item, NestedInteractive } from 'salad/components'
import * as Icons from 'salad/icons'
import { DiffText, Ellipsis, Text, VStack } from 'salad/primitives'
import { styled, theme } from 'salad/stitches'

const AttachmentItemBase = styled(Item.Root, {
  variants: {
    variant: {
      default: {
        width: 'fit-content',
        paddingInlineEnd: theme.space[12],
      },
      'new-file': {
        width: 'fit-content',
        backgroundColor: theme.colors.white,
        maxWidth: '218px',
        border: `2px solid ${theme.colors['grey-10']}`,
      },
      bordered: {
        backgroundColor: theme.colors.white,
        border: `2px solid ${theme.colors['grey-10']}`,
        padding: theme.space[20],
        paddingInlineEnd: theme.space[16],
      },
      deleted: {},
    },
  },
})

interface AttachmentItemProps
  extends Omit<Item.ItemProps, 'variant'>,
    VariantProps<typeof AttachmentItemBase> {
  fileName: string
  mimeType: string
  onRemove?: () => void
  onClick?: () => void
  children?: React.ReactNode
}

export const AttachmentItem = ({
  fileName,
  mimeType,
  onClick,
  onRemove,
  children,
  variant = 'default',
  ...rest
}: AttachmentItemProps) => {
  const shortType = mimeType.substring(mimeType.lastIndexOf('/') + 1).toLocaleUpperCase()
  // .docx mime type is something crazy, render DOCX instead
  const renderedType =
    shortType.length > 5
      ? fileName.substring(fileName.lastIndexOf('.') + 1).toLocaleUpperCase()
      : shortType

  return (
    <NestedInteractive.Root>
      <AttachmentItemBase
        as="span"
        interactive={['default', 'bordered'].includes(variant)}
        size="small"
        variant={variant}
        {...rest}
      >
        <Item.Start>
          <Avatar
            size="medium"
            variant="square"
            color={variant === 'deleted' ? 'deleted' : 'auto'}
          >
            <Icons.S16.File />
          </Avatar>
        </Item.Start>
        <NestedInteractive.Action onClick={onClick}>
          <Item.Content>
            {variant === 'deleted' ? (
              <VStack>
                <Ellipsis>
                  <DiffText diff="before" variant="title-14">
                    {fileName}
                  </DiffText>
                </Ellipsis>

                <DiffText diff="before" variant="label-16">
                  {renderedType}
                </DiffText>
              </VStack>
            ) : (
              <VStack>
                <Ellipsis>
                  <Text variant="title-14">{fileName}</Text>
                </Ellipsis>
                <Text variant="label-16" color="grey-60">
                  {renderedType}
                </Text>
              </VStack>
            )}
          </Item.Content>
        </NestedInteractive.Action>
        {children}
        {onRemove && (
          <Item.End css={{ padding: theme.space[4] }}>
            <IconButton size={24} onClick={onRemove}>
              <Icons.S16.Cross />
            </IconButton>
          </Item.End>
        )}
      </AttachmentItemBase>
    </NestedInteractive.Root>
  )
}
